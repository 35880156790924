import { defineStore } from "pinia";
import { useMainStore } from "@/store/main";
import { useDashboardStore } from "@/store/dashboard";
import { CamDataType } from "@/types/cam_data";
import { API_URL } from "@/utils/urls";
import { SensorType } from "@/types/sensor";
import { ProfileType } from "@/types/profile";
import { BatchInfoType } from "@/types/cam_batch"

const MAIN_STORE = useMainStore();
const DASHBOARD_STORE = useDashboardStore();

export const useMaintenanceStore = defineStore("maintenanceStore", {
  state: () => ({
    cams_data: [] as CamDataType[],
    feeds_profiles_data: [] as ProfileType[],
    micAudioReport: {}
  }),
  actions: {
    async setCamInfo(): Promise<void> {
      const sensors: SensorType[] = DASHBOARD_STORE.getAllSensors.filter(
        (sensor: SensorType) => (sensor.type === "smartcam" && sensor.status === "installed")
      );
      for (const sensor of sensors) {
        try {
          const barn = await MAIN_STORE.getFromAPI(`${API_URL}/barns/${sensor.barn}`);
          const sensor_acquisitions = await MAIN_STORE.getAllFromAPI(`${API_URL}/smartcam_v1_acquisitions/?camera_mac=${sensor.mac}`);
          const batchesInfo: BatchInfoType[] = [];
          const batch_accommodation_promises = barn.batches.map(async (batch_accommodation: any) => {
            try {
              const batch = await MAIN_STORE.getFromAPI(`${API_URL}/batches/${batch_accommodation}`);
              const sensor_weights = await MAIN_STORE.getAllFromAPI(`${API_URL}/batch_weights/?batch=${batch.pk}`, [], false);
              const sensor_weights_v2 = await MAIN_STORE.getAllFromAPI(`${API_URL}/batch_weights_v2/?batch=${batch.pk}`, [], false);
              const today = new Date();
              let filtered_sensor_acquisitions = [];
              if (sensor_acquisitions.length > 0) {
                filtered_sensor_acquisitions = sensor_acquisitions.filter((acquisitions: any) => {
                  if (batch.date_accomodation !== null) {
                    const date_accomodation = new Date(batch.date_accomodation);
                    const date_departure = batch.date_departure ? new Date(batch.date_departure) : today;
                    return new Date(acquisitions.date_fetched) >= date_accomodation && new Date(acquisitions.date_fetched) <= date_departure;
                  }
                  return false;
                });
              }
              const open = !batch.date_departure;
              const age = open ? (new Date().getTime() - new Date(batch.date_accomodation).getTime()) / (1000 * 3600 * 24) : (new Date(batch.date_departure).getTime() - new Date(batch.date_accomodation).getTime()) / (1000 * 3600 * 24);
              batchesInfo.push({
                batch,
                batch_acquisitions: filtered_sensor_acquisitions,
                batch_weights: sensor_weights,
                batch_weights_v2: sensor_weights_v2,
                open,
                age
              });
            } catch(error) {
              console.log('Batch Error:', error);
            }
          });

          await Promise.all(batch_accommodation_promises);
          batchesInfo.sort((a, b) => b.batch.pk - a.batch.pk);
          this.cams_data.push({
            sensor: sensor,
            barn: barn,
            batches: batchesInfo
          });
        } catch(error) {
          console.log('Sensor Error:', error)
        }
      }

    },
    async setFeedInfo(): Promise<void> {
      try {
        const profiles = await MAIN_STORE.getFromAPI(`${API_URL}/silo_profiles/last_profiles/`);
        this.feeds_profiles_data.push(...profiles.results)
      } 
      catch (error) {
        //continue
      }
    },
    async fetchMicAudiosReport(): Promise<void> {
      const yesterday = new Date(Date.now() - 86400000); // 86400000 milliseconds in a day
      const dateStr = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;
    
      const response = await fetch(`https://machinelearning.pecsmart.cloud/s3/get_mic_audios_report?date_str=${dateStr}`);
      const data = await response.json();
    
      for (const [farm, centrals] of Object.entries(data)) {
        for (const [central, mics] of Object.entries(centrals as Record<string, any>)) {
          for (const [mic, micInfo] of Object.entries(mics as Record<string, any>)) {
            const audio_path = micInfo.audio_path;
            // Fetch audio blob from new FastAPI route
            const audioResponse = await fetch(`https://machinelearning.pecsmart.cloud/s3/get_audio?audio_path=${audio_path}`);
            const audioBlob = await audioResponse.blob();
            // Update mic info with new audio blob URL
            micInfo.audio = URL.createObjectURL(audioBlob);
          }
        }
      }
    
      this.micAudioReport = data;
    }
    
  },
  getters: {
    getMicReportData: state => state.micAudioReport,
    getCamsData: state => state.cams_data,
    getFeedsData: state => state.feeds_profiles_data,
    getFeedProfileBySensor() {
      return (feed_pk: number | undefined): ProfileType | null => {
        const profile = this.feeds_profiles_data.find((profile: ProfileType) => feed_pk === profile.sensor)
        return profile ? profile : null
      }
    },
  },
});   